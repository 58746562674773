<template>
  <component
    v-bind="$attrs"
    :is="tag"
    class="admin-link"
    :href="adminURL"
    target="_blank"
    @click.stop
  >
    <slot />

    <v-icon size="x-small">
      mdi-open-in-new
    </v-icon>
  </component>
</template>

<script setup lang="ts">
export interface Props {
  to: string
  tag?: string
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'a',
})

const { $api } = useNuxtApp()
const adminURL = computed(() => $api.v1.getAdminURL(props.to))
</script>

<style scoped lang="scss">
.admin-link {
  text-decoration: none;
}

.v-icon {
  margin-left: 3px;
  position: relative;
  top: -5px;
}
</style>
